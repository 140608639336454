import { useKeycloak } from "@react-keycloak/web";
//import { Navigate } from "react-router";

const PrivateComponent = ({children}) =>{
    const {keycloak} = useKeycloak();
    console.log("custom test 1", keycloak)
    const isLoggedIn = keycloak.authenticated;
    console.log("Custom test",isLoggedIn)
    return isLoggedIn ? children : null;
}

export default PrivateComponent;